
.footer {
    width: 90vw;
    padding: 30px 15vw 45px 15vw;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #ffffff;
  }
  
  .footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background: linear-gradient(to right, rgb(25,25,25) 7.5%, rgb(50,50,50) 15%, rgb(50,50,50) 85%, rgb(25,25,25) 92.5%);
  }
  
  .footer-left {
    font-size: 16px;
    color: white;
    text-decoration: none;
  }

  .footer-left img {
    margin: -2.5px 0px 0px 10px;
    width: 25px;
    height: auto;
    animation: spin 3.3s linear infinite;
  }
  
  .footer-right {
    display: flex;
    gap: 20px;
  }

  .footer-right a img {
    width: 25px;
    height: auto;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  