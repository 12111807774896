body {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Nunito', sans-serif;
  font-size: 20px;

  background-color: rgb(25,25,25);
  color: white;

}

.mockContent {
  padding: 500px 0px;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}