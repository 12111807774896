
.navbar {
  width: 90vw;
  padding: 60px 15vw 15px 15vw;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.navbar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: linear-gradient(to right, rgb(25,25,25) 7.5%, rgb(50,50,50) 15%, rgb(50,50,50) 85%, rgb(25,25,25) 92.5%);
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

.navbar-brand a {
text-decoration: none;
color: white;
}

.navbar-pages {
  display: flex;
  gap: 10px;
  list-style-type: none;
  padding: 0px;
}

.navbar-pages a {
  padding: 10px 25px 10px 25px;
  min-width: 75px;
  border-radius: 10px;
  color: white;
  text-decoration: none;
}

.navbar-pages a:hover {
  background-color: rgb(35,35,35);
}


.navbar-btn-contact {
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;

  background-color: #6200EE;
  color: white;

}

.navbar-btn-contact:hover {
  background-color: #8700f1;
}